import { useEffect } from "react";
import { createContext, useState } from "react";
import axios from "axios";
import useAuth from "../hooks/useAuth";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios
      .get("/api/data/checkauth", config)
      .then((res) => {
        const { username, role } = res.data;
        const id = res.data._id;

        if (res.data.username) {
          setAuth({ id, username, role });
        } else {
          setAuth(null);
        }
      })
      .catch((e) => {
        setAuth({ message: "Not Authorized" });
      });
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ auth, setAuth }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
