import React, { useState } from "react";
import "./styles.css";
import axios from "axios";

import useAuth from "../../hooks/useAuth";

import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import { getByDisplayValue } from "@testing-library/react";
import { Menu } from "antd";

const { SubMenu } = Menu;

const Navbar = () => {
  const [current, setCurrent] = useState();
  let navigate = useNavigate();
  const { auth } = useAuth();
  const { setAuth } = useAuth();

  const handleClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    navigate("/" + e.key);
  };

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const handleLogout = () => {
    // navigate("/login");

    axios
      .get("/api/logout", config)
      .then((res) => {
        navigate("/");
      })
      .then(() => {
        console.log("auth", auth);
        setAuth({});
      })
      .then(() => {
        console.log("auth despues", auth);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div className="navbar-container">
      <div className="logo">Awesome Blossom</div>
      <div className="menu-items">
        <Menu
          style={{ borderBottom: "none" }}
          onClick={handleClick}
          selectedKeys={current}
          mode="horizontal"
          disabledOverflow="true"
        >
          <Menu.Item key="home">Home</Menu.Item>
          <Menu.Item key="employees">Employees</Menu.Item>
          <SubMenu key="submenu" title="Reports">
            <Menu.ItemGroup title="Sales">
              <Menu.Item key="reportshour">Sales by Hour</Menu.Item>
              <Menu.Item key="reportday">Sales by Day</Menu.Item>
              <Menu.Item key="salesbycategory">Sales by Category</Menu.Item>
              <Menu.Item key="retiros">Retiros</Menu.Item>
              <Menu.Item key="turnos">Turnos</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="More">
              <Menu.Item key="salesbyemployee">Sales by Employee</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item key="tickets">Tickets</Menu.Item>
          <Menu.Item key="cierres">Cierres</Menu.Item>
          <Menu.Item key="sucursales">Sucursales</Menu.Item>
          <Menu.Item key="usuarios">Usuarios</Menu.Item>
        </Menu>
      </div>
      {auth.username ? (
        <div className="login-avatar">
          <Avatar
            style={{
              backgroundColor: "#87d068",
            }}
            icon={<UserOutlined />}
            key="login"
          />{" "}
          {` Welcome ${auth.username}!`}
          <div className="logout" onClick={handleLogout}>
            Logout
          </div>
        </div>
      ) : (
        <div className="login" onClick={handleLogin}>
          Login
        </div>
      )}
    </div>
  );
};

export default Navbar;
