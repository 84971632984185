import "./App.css";
import React from "react";
import { useEffect } from "react";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import axios from "axios";
import useAuth from "./hooks/useAuth";

import Home from "./pages/Home";
import Employees from "./pages/Employees";

import Menu from "./pages/Menu";
import ReportDay from "./pages/ReportDay";
import Registros from "./pages/Registros";
import Sucursales from "./pages/Sucursales";
import LogIn from "./pages/LogIn";
import Usuarios from "./pages/Usuarios";
import Categories from "./pages/SalesCategories";
import SalesByEmployee from "./pages/SalesEmployee";
import Retiros from "./pages/Retiros";
import { Tickets } from "./pages/Tickets";
// import Cierres from "./pages/Cierres";
import Turnos from "./pages/Turnos";

function App() {
  const { auth } = useAuth();
  const { setAuth } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route path="*" element={<Home />} /> */}
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/tickets" element={<Tickets />} />
        {/* Private Routes */}
        <Route path="/employees" element={<Employees />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/reportday" element={<ReportDay />} />
        <Route path="registros" element={<Registros />} />
        <Route path="turnos" element={<Turnos />} />
        <Route path="/salesbycategory" element={<Categories />} />
        <Route path="/retiros" element={<Retiros />} />
        {/* <Route path="/cierres" element={<Cierres />} /> */}
        {/* Admin Routes */}
        <Route element={<RequireAuth />}>
          <Route path="/sucursales" element={<Sucursales />} />
          <Route path="/usuarios" element={<Usuarios />} />
        </Route>
      </Route>
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}

export default App;
