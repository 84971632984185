import React, { useState } from "react";
import AddLocationFormButton from "../AddLocationFormButton";
import "./styles.css";
import {
  Table,
  Input,
  InputNumber,
  Button,
  Tag,
  Popconfirm,
  Form,
  Typography,
} from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const UserTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(props.usuarios);
  const [editingid, setEditingid] = useState("");

  const isEditing = (record) => record.id === editingid;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      pin: "",
      username: "",
      password: "",
      status: "",
      ...record,
    });
    setEditingid(record.id);
  };

  const cancel = () => {
    setEditingid("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingid("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingid("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "USERNAME",
      dataIndex: "username",
      width: "15%",
      editable: true,
    },
    {
      title: "PASSWORD",
      dataIndex: "password",
      width: "15%",
      editable: true,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: "10%",
      editable: false,
      render: (_, record) => {
        return record.status ? (
          <Tag color="green" className="tag">
            WORKING
          </Tag>
        ) : (
          <Tag color="volcano" className="tag">
            NOT WORKING
          </Tag>
        );
      },
    },
    {
      title: "OPERATION",
      dataIndex: "operation",
      width: "15%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="actions">
            <Typography.Link
              disabled={editingid !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleDelete = (id) => {
    const dataSource = [...data];

    setData(dataSource.filter((item) => item.id !== id));
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "id" ? "username" : "password" && "status",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <AddLocationFormButton data={data} setData={setData} />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey="id"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};

export default UserTable;
