import React, { useState } from "react";

import RetirosTable from "../../components/RetirosTable";

function AllRetiros() {
  const [data, setData] = useState();

  return (
    <div>
      <div className="container">
        <RetirosTable />
      </div>
    </div>
  );
}

export default AllRetiros;
