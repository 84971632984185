import React from "react";
import "./styles.css";

import { Layout } from "antd";
import { Button } from "antd";
import Navbar from "../../components/Navbar";
import NormalLoginForm from "../LogIn";
import Pricing from "../../components/PricingOptions";

const { Header, Footer, Sider, Content } = Layout;

const Home = (props) => {
  // console.log("props", props);
  return (
    <div className="container">
      <Navbar />
      <Layout>
        <Pricing />
      </Layout>
    </div>
  );
};

export default Home;
