import React, { Children } from "react";
import "./styles.css";

import { Table } from "antd";
import "antd/dist/antd.css";
const moment = require("moment");
require("moment/locale/es");
moment.locale("ES");

const capitalizeFirst = require("../../utils/capitalizeFirst");
const {
  colorGradient,
  lowColor,
  mediumColor,
  highColor,
} = require("../../utils/gradient");

// let min = 0;
// let max = 0;

// const columns = (total) => [
//   {
//     title: "Date",
//     dataIndex: "Date",
//     width: "40%",
//     align: "right",
//     render: (_, record) => {
//       return moment(record.Date).format("DD-MM-YYYY");
//     },
//   },
//   {
//     title: "Day",
//     dataIndex: "Date",
//     width: "25%",
//     align: "right",
//     render: (_, record) => {
//       return capitalizeFirst(moment(record.Date).locale("es").format("dddd"));
//     },
//   },
//   // {
//   //   title: "Sales",
//   //   dataIndex: "Total",
//   //   width: "25%",
//   //   align: "right",
//   // },
//   {
//     title: "Sales",
//     dataIndex: "Total",
//     width: "25%",
//     align: "right",
//     render: (_, record) => {
//       return {
//         props: {
//           style: {
//             background: colorGradient(
//               (record.Total - min) / (max - min),
//               lowColor,
//               mediumColor,
//               highColor
//             ),
//             fontWeight: 550,
//           },
//         },
//         children: <div>{record.Total.toLocaleString()}</div>,
//       };
//     },
//   },
//   {
//     title: "Tickets",
//     width: "18%",
//     dataIndex: "Qty",
//     align: "right",
//   },
//   {
//     title: "Avg",
//     dataIndex: "Avg",
//     width: "18%",
//     align: "right",
//   },
//   {
//     title: "%",
//     dataIndex: "percentage",
//     render: (_, record) => {
//       return ((record.Total * 100) / total).toFixed(2);
//     },
//     align: "right",
//   },
// ];

const SalesTable = (props) => {
  // console.log("new props", props.data);
  let data = props.data.data;

  let min = 0;
  let max = 0;

  const columns = (total) => [
    {
      title: "Date",
      dataIndex: "Date",
      width: "45%",
      align: "right",
      render: (_, record) => {
        return moment(record.Date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Day",
      dataIndex: "Date",
      width: "25%",
      align: "right",
      render: (_, record) => {
        return capitalizeFirst(moment(record.Date).locale("es").format("dddd"));
      },
    },
    // {
    //   title: "Sales",
    //   dataIndex: "Total",
    //   width: "25%",
    //   align: "right",
    // },
    {
      title: "Sales",
      dataIndex: "Total",
      width: "25%",
      align: "right",
      render: (_, record) => {
        return {
          props: {
            style: {
              background: colorGradient(
                (record.Total - min) / (max - min),
                lowColor,
                mediumColor,
                highColor
              ),
              fontWeight: 550,
            },
          },
          children: <div>{record.Total.toLocaleString()}</div>,
        };
      },
    },
    {
      title: "Tickets",
      width: "18%",
      dataIndex: "Qty",
      align: "right",
    },
    {
      title: "Avg",
      dataIndex: "Avg",
      width: "18%",
      align: "right",
    },
    {
      title: "%",
      dataIndex: "percentage",
      render: (_, record) => {
        return ((record.Total * 100) / total).toFixed(2);
      },
      align: "right",
    },
  ];

  const totalSales = (sales) => {
    let total = 0;

    sales.forEach((sale) => {
      total += parseInt(sale.Total);
    });
    return total;
  };

  const totalTickets = (tickets) => {
    let totalTickets = 0;
    tickets.forEach((ticket) => {
      totalTickets += parseInt(ticket.Qty);
    });
    return totalTickets;
  };

  const totalAvg = (data) => {
    let totalAvg = 0;
    data.forEach((e) => {
      totalAvg += e.Avg;
    });

    return totalAvg.toFixed(2);
  };

  const total = totalSales(props.data.data);
  const tickets = totalTickets(props.data.data);
  const totalavg = totalAvg(props.data.data);

  min = Math.min.apply(
    null,
    props.data.data.map((e) => e.Total)
  );

  max = Math.max.apply(
    null,
    props.data.data.map((e) => e.Total)
  );

  // console.log("lalalalala", props.data.data);

  console.log(min, max);
  // let value = (e - min) / (max - min);

  const percentage = (data) => {
    let resultado = 0;
    data.forEach((e) => {
      resultado += (e.Total * 100) / total;
    });

    return Math.round(resultado).toFixed(2);
  };

  return (
    <div className="table-sales">
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns(total)}
        dataSource={props.data.data}
        size="small"
        pagination={false}
        rowKey="Date"
        bordered
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Totals</Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={1}>
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={1}>
                <div className="summary-total">{total.toLocaleString()}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={2}>
                {tickets}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={3}>
                {(total / tickets).toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={4}>
                {percentage(props.data.data)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
};

export default SalesTable;
