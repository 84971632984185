import { Button, Card, Col, Row, Select, Typography } from "antd";
import "./styles.css";

const { Title } = Typography;

const Pricing = () => {
  return (
    <div className="site-card-wrapper">
      <Title className="title">Pricing</Title>
      <Row>
        <Col lg={{ span: 6, offset: 1 }} className="spacing">
          <Card title="Card title" bordered={false} hoverable className="card">
            Card content #1
          </Card>
          <Button type="primary" block className="price-btn">
            Select
          </Button>
        </Col>

        <Col lg={{ span: 6, offset: 1 }} className="spacing">
          <Card title="Card title" bordered={false} hoverable className="card">
            Card content
          </Card>
          <Button type="primary" block>
            Select
          </Button>
        </Col>
        <Col lg={{ span: 6, offset: 1 }} className="spacing">
          <Card title="Card title" bordered={false} hoverable className="card">
            Card content
          </Card>
          <Button type="primary" block>
            Select
          </Button>
        </Col>
        <Col lg={{ span: 6, offset: 3 }} className="spacing">
          <Card title="Card title" bordered={false} hoverable className="card">
            Card content
          </Card>
          <Button type="primary" block>
            Select
          </Button>
        </Col>
        <Col lg={{ span: 6, offset: 3 }} className="spacing">
          <Card title="Card title" bordered={false} hoverable className="card">
            Card content
          </Card>
          <Button type="primary" block>
            Select
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default Pricing;
