import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import Navbar from "../../components/Navbar";
import SalesTable from "../../components/SalesTable";
import FilterRetiros from "../../components/FilterRetiros";

import "./styles.css";
import { Button } from "antd";

const RetirosTable = (props) => {
  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [data, setData] = useState();

  console.log(data);

  return (
    <div className="container">
      <Navbar />
      <FilterRetiros setData={setData} />
      <div className="table-container-sales">
        {data ? (
          data.map((e, i) => {
            console.log(i);
            return (
              <div key={i}>
                <SalesTable data={e} />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default RetirosTable;
