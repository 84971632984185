import React from "react";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Filter from "../../components/Filter";
import SalesEmployee from "../../components/SalesEmployees";

function SalesByEmployee() {
  return (
    <div>
      <div className="container">
        <Navbar />
        <Filter />
        <SalesEmployee />
      </div>
    </div>
  );
}

export default SalesByEmployee;
