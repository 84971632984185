import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

import "./styles.css";
import "antd/dist/antd.css";

import { DatePicker, Space, Button } from "antd";
import { Select } from "antd";
import { Typography } from "antd";
const { Text } = Typography;

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const { Option } = Select;

const config = {
  withCredentials: true,
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

const Filter = (props) => {
  const [from, setFrom] = useState(
    `'${moment().startOf("month").format("YYYY-MM-DD")}'`
  );
  const [to, setTo] = useState(`'${moment().format("YYYY-MM-DD")}'`);

  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursales, setSelectedSucursales] = useState([]);

  let listaSucursales = [];

  useEffect(() => {
    console.log("selected", selectedSucursales);

    axios
      .get("/api/data/getconnections", config)
      .then((res) => {
        setSucursales(res.data);
      })
      .catch((err) => console.log(err));
    handleGo();
  }, [selectedSucursales, from, to]);

  listaSucursales.push(<Option key="all">Todas / All</Option>);

  sucursales.forEach((e, i) => {
    listaSucursales.push(
      <Option key={sucursales[i].name}>{sucursales[i].name}</Option>
    );
  });

  const handleDate = (value) => {
    console.log("value", value);
    if (value !== null) {
      setFrom(`'${moment(value[0]._d).format().split("T")[0]}'`);
      setTo(`'${moment(value[1]._d).format().split("T")[0]}'`);
    } else {
      props.setData("");
    }
  };

  const handleSelection = (value) => {
    if (value.includes("all")) {
      let all = [];
      sucursales.forEach((e) => {
        all.push(e.name);
      });

      setSelectedSucursales(all);
    } else {
      console.log(`selected ${value}`);
      setSelectedSucursales(value, ...selectedSucursales);
    }
  };

  const handleGo = () => {
    props.setLoading(true);

    let queryString = "";
    selectedSucursales.forEach((e, i) => {
      queryString += `&selection=${e}`;
    });

    console.log("qs", queryString);

    // axios
    //   .get(`/api/data/livesales?from=${from}&to=${to}`, config)
    //   .then((result) => {
    //     props.setLoading(false);
    //     props.setData(result.data);
    //   })
    //   .catch((e) => console.log(e));

    //Convert get to a post request including selectedSucursales in the body.

    console.log("sql", from, to, selectedSucursales);

    axios
      .post(
        `/api/data/salesbyday`,
        {
          selectedSucursales,
          from,
          to,
        },
        config
      )
      .then((res) => {
        props.setLoading(false);
        props.setData(res.data);
        console.log("reporte by day", res.data);
      })
      .catch((e) => console.log(e));

    console.log("sucursales seleccionadas", selectedSucursales);
  };

  return (
    <div className="container-filter">
      <div className="datepicker-container">
        <Space direction="vertical" size={0} className="datepicker">
          <Text>Rango de fechas</Text>
          <RangePicker
            format="DD-MM-YYYY"
            onChange={handleDate}
            defaultValue={[moment().startOf("month"), moment()]}
          />
        </Space>
      </div>

      <div className="select">
        <Text>Seleccion de Sucursal</Text>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Please select"
          defaultValue={[]}
          onChange={handleSelection}
        >
          {listaSucursales}
        </Select>
      </div>
      {/* <div className="btn-filter">
        <Button type="primary" onClick={handleGo}>
          Go!
        </Button>
      </div> */}
    </div>
  );
};

export default Filter;
