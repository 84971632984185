import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import "./styles.css";
import Logo from "../../components/Logo";

import { Layout } from "antd";

const { Header, Footer, Sider, Content } = Layout;

const NormalLoginForm = () => {
  const { setAuth } = useAuth();
  const { auth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  console.log(setAuth);
  const onFinish = (values) => {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        "/api/login",
        {
          username: values.username,
          password: values.password,
        },
        config
      )
      .then((res) => {
        const { username, role } = res.data;
        const id = res.data._id;
        console.log("la data: ", id, username, role);
        setAuth({ id, username, role });
      })
      .then(() => {
        console.log("auth", auth);
        navigate(from, { replace: true });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="login-container">
      <Content className="login-container-content">
        <Logo />

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item>

          <Form.Item className="login-footer">
            <div className="space">
              <a href="">register now!</a>

              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Content>
      {/* <Footer>Footer</Footer> */}
    </div>
  );
};
export default NormalLoginForm;

// ReactDOM.render(<NormalLoginForm />, mountNode);
// #components-form-demo-normal-login .login-form {
//   width: '300px';
// }
// #components-form-demo-normal-login .login-form-forgot {
//   float: right;
// }
// #components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
//   float: left;
// }
// #components-form-demo-normal-login .login-form-button {
//   width: '100%';
// }
