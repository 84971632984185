import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DataGrid from "devextreme-react/data-grid";
import { customers } from "./data.js";

const columns = ["CompanyName", "Sales", "City", "State", "Phone", "Fax"];

export const Tickets = () => {
  console.log(DataGrid.protoTypes);
  return (
    <DataGrid
      dataSource={customers}
      keyExpr="ID"
      defaultColumns={columns}
      showBorders={true}
    />
  );
};
