import React, { useState } from "react";
import AddLocationFormButton from "../AddLocationFormButton";
import "./styles.css";
import { Spin } from "antd";

import axios from "axios";
import {
  Table,
  Input,
  InputNumber,
  Button,
  Tag,
  Popconfirm,
  Form,
  Typography,
} from "antd";

const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ConnectionTable = (props) => {
  const [form] = Form.useForm();

  const [data, setData] = useState(props.sucursales);
  const [editingid, setEditingid] = useState("");
  const datas = [...props.sucursales];

  console.log("Status", props.status);

  const isEditing = (record) => record._id === editingid;

  // const config = {
  //   withCredentials: true,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  const edit = (record) => {
    console.log("edit, record", record);
    console.log("form", form);
    form.setFieldsValue({
      name: "",
      server: "",
      database: "",
      username: "",
      password: "",
      status: "",
      ...record,
    });
    setEditingid(record._id);
  };

  const cancel = () => {
    setEditingid("");
  };

  const save = async (id) => {
    const editFields = await form.validateFields();
    const newData = { id, ...editFields };

    console.log("newData", newData);
    axios
      .post("/api/data/editconnection", newData, config)
      .then((response) => {
        setEditingid("");
        props.rerender();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "15%",
      editable: true,
    },
    {
      title: "SERVER",
      dataIndex: "server",
      width: "30%",
      editable: true,
    },
    {
      title: "DATABASE",
      dataIndex: "database",
      width: "15%",
      editable: true,
    },
    {
      title: "USER",
      dataIndex: "username",
      width: "15%",
      editable: true,
    },
    {
      title: "PASSWORD",
      dataIndex: "password",
      width: "15%",
      editable: true,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: "10%",
      editable: false,
      render: (_, record) => {
        if (props.statusLoading) {
          return <Spin size="small" />;
        }

        return props.status[record.name] ? (
          <Tag color="green" className="tag">
            Online
          </Tag>
        ) : (
          <Tag color="volcano" className="tag">
            Offline
          </Tag>
        );
      },
    },
    {
      title: "OPERATION",
      dataIndex: "operation",
      width: "15%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record._id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="actions">
            <Typography.Link
              disabled={editingid !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => {
                handleDelete(record);
              }}
            >
              <a>Delete</a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleDelete = (record) => {
    let data = { id: record._id };

    console.log(data);

    axios
      .delete("/api/data/deleteconnection", {
        headers: { "Content-Type": "application/json" },
        data: data,
        withCredentials: true,
      })
      .then((response) => {
        props.rerender();
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.delete("/api/data/removecon", {
    //   headers: { "Content-Type": "application/json" },
    //   data: { id: 123 },
    //   withCredentials: true,
    // });
  };

  // const config = {
  //   withCredentials: true,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "id" ? "name" : "server" && "status",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <AddLocationFormButton
        rerender={props.rerender}
        data={data}
        setData={setData}
        addLocation={props.addLocation}
        setAddLocation={props.setAddLocation}
      />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={datas}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey="_id"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};

export default ConnectionTable;
