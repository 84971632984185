import React from "react";
import "./styles.css";
import Image from "../../assets/img/logo.png";

function Logo() {
  return (
    <div>
      <img src={Image} className="logo" />
    </div>
  );
}

export default Logo;
