import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import ConnectionTable from "../../components/ConnectionTable";
import branches from "../../assets/storeBranches.json";

const Sucursales = () => {
  const [sucursales, setSucursales] = useState([]);
  const [editSucursales, setEditSucursales] = useState();
  const [addLocation, setAddLocation] = useState();
  const [status, setStatus] = useState({});
  const [statusLoading, setStatusLoading] = useState(true);

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const rerender = () => {
    axios
      .get("/api/data/getconnections", config)
      .then((res) => {
        setSucursales(res.data);
        setEditSucursales(res.data);
        setAddLocation(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("/api/data/ping", config)
      .then((res) => {
        setStatus(res.data);
        setStatusLoading(false);
        console.log("ping", res);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    axios
      .get("/api/data/getconnections", config)
      .then((res) => {
        setSucursales(res.data);
        setEditSucursales(res.data);
        setAddLocation(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("/api/data/ping", config)
      .then((res) => {
        setStatus(res.data);
        setStatusLoading(false);
        console.log("ping", res);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="container">
      <Navbar />
      <ConnectionTable
        rerender={rerender}
        branches={branches}
        sucursales={sucursales}
        status={status}
        statusLoading={statusLoading}
        setSucursales={setSucursales}
        editSucursales={editSucursales}
        setEditSucursales={setEditSucursales}
        addLocation={addLocation}
        setAddLocation={setAddLocation}
      />
    </div>
  );
};

export default Sucursales;
