import React, { useState, createRef } from "react";
import axios from "axios";

import { message, Space, Modal, Button, Form, Input } from "antd";

import "./styles.css";

const AddLocationFormButton = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Create a New Connection");

  const [form] = Form.useForm();

  const [location, setLocation] = useState({
    name: "",
    server: "",
    database: "",
    username: "",
    password: "",
    status: true,
  });

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const showModal = () => {
    setVisible(true);
  };

  const success = () => {
    message.success("Location added successfully");
  };

  const error = () => {
    message.error("Location name already exists");
  };

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");

    setConfirmLoading(true);

    axios
      .post(
        "/api/data/addconnection",
        {
          location,
        },
        config
      )
      .then((res) => {
        if (res.status === 201) {
          setLocation({
            name: "",
            server: "",
            database: "",
            username: "",
            password: "",
            status: true,
          });

          success();
          props.rerender();
        } else if (res.status === 200) {
          error();
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputLocation = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLocation({ ...location, [name]: value });
  };

  return (
    <>
      <Button className="btn" type="primary" onClick={showModal}>
        ADD NEW LOCATION
      </Button>
      <Modal
        className="modal"
        title="Add Location"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        getContainer={false} // This was it
      >
        <p>{modalText}</p>
        <Form
          initialValues={{
            name: location.name,
            server: location.server,
            database: location.database,
            username: location.username,
            password: location.password,
          }}
        >
          <Form.Item
            className="form"
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Location name",
              },
            ]}
          >
            <Input
              placeholder="Location name"
              className="input"
              name="name"
              value={location.name}
              onChange={handleInputLocation}
            />
          </Form.Item>
          <Form.Item
            className="form"
            name="server"
            label="Server Number"
            rules={[
              {
                required: true,
                message: "Server Number",
              },
            ]}
          >
            <Input
              placeholder="Server"
              className="input"
              name="server"
              onChange={handleInputLocation}
            />
          </Form.Item>
          <Form.Item
            className="form"
            name="database"
            label="Database"
            rules={[
              {
                required: true,
                message: "Database",
              },
            ]}
          >
            <Input
              placeholder="Database"
              className="input"
              name="database"
              onChange={handleInputLocation}
            />
          </Form.Item>

          <Form.Item
            className="form"
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Username",
              },
            ]}
          >
            <Input
              placeholder="Username"
              className="input"
              name="username"
              onChange={handleInputLocation}
            />
          </Form.Item>

          <Form.Item
            className="form"
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Password"
              className="input"
              name="password"
              onChange={handleInputLocation}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddLocationFormButton;
