import React, { useState } from "react";

import moment from "moment";
import axios from "axios";

import "./styles.css";
import "antd/dist/antd.css";

import { DatePicker, Space, Button } from "antd";
import { Select } from "antd";
import { Typography } from "antd";
const { Text } = Typography;

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

const config = {
  withCredentials: true,
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

function onSearch(val) {
  console.log("search:", val);
}

const FilterRetiros = (props) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  console.log("From", from);
  console.log("To", to);

  const handleGo = () => {
    axios
      .get(`/api/data/retiros?from=${from}&to=${to}`, config)
      .then((result) => {
        props.setData(result.data);
      })
      .catch((e) => console.log(e));
  };

  const handleDate = (value) => {
    setFrom(`'${moment(value[0]._d).format().split("T")[0]}'`);
    setTo(`'${moment(value[1]._d).format().split("T")[0]}'`);
  };

  return (
    <div className="container-filter">
      <div className="datepicker-container">
        <Space direction="vertical" size={0} className="datepicker">
          <Text>Rango de fechas</Text>
          <RangePicker format="DD-MM-YYYY" onChange={handleDate} />
        </Space>
      </div>

      <div className="select">
        <Text>Nombre del personal</Text>
        <Select
          className="select-filter"
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="tom">Tom</Option>
        </Select>
      </div>
      <div className="btn-filter">
        <Button type="primary" onClick={handleGo}>
          Go!
        </Button>
      </div>
    </div>
  );
};

export default FilterRetiros;
