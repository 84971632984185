import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import axios from "axios";

const RequireAuth = () => {
  const { auth } = useAuth();
  const { setAuth } = useAuth();
  const location = useLocation();

  if (Object.keys(auth).length === 0) {
    return null;
  }

  return auth.username ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
