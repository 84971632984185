import React, { useState } from "react";
import "./styles.css";
import Filter from "../../components/Filter";
import Navbar from "../../components/Navbar";
import ReportsTable from "../../components/ReportsTable/index";
import sales from "../../assets/sales.json";
import DailySalesTable from "../../components/DailySalesTable";
import SalesTable from "../../components/SalesTable";
import WidgetBar from "../../components/WidgetBar";
import { Badge } from "antd";

import { Spin, Card, Statistic } from "antd";

const moment = require("moment");
require("moment/locale/es");
moment.locale("ES");

const Reports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("data in reports", data);

  let total;
  let personas;

  let cae, neto, iva;

  if (Array.isArray(data)) {
    total = data.reduce((a, b) => {
      return a + b.total;
    }, 0);

    personas = data.reduce((a, b) => {
      return a + b.people;
    }, 0);

    cae = data.reduce((a, b) => {
      return a + b.cae.total;
    }, 0);

    neto = data.reduce((a, b) => {
      return a + b.cae.importeneto;
    }, 0);

    iva = data.reduce((a, b) => {
      return a + b.cae.iva;
    }, 0);
  }

  console.log(personas);

  console.log(typeof personas, personas);

  return (
    <div className="container">
      <Navbar />
      <WidgetBar></WidgetBar>
      <Filter setData={setData} setLoading={setLoading} />
      {data.length ? (
        <Card>
          <div className="statistics">
            <div className="stat">
              <Statistic
                title="Totales"
                value={total.toLocaleString()}
                // precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={"$"}
                suffix=""
              />
            </div>
            <div className="stat">
              <Statistic
                title="Clientes"
                value={personas.toLocaleString()}
                // precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={""}
                suffix=""
              />
            </div>
            <div className="stat">
              <Badge
                offset={[-15, 10]}
                count={((cae / total) * 100).toFixed(2)}
                title="Porcentage"
                color="#A149FA"
              >
                <Statistic
                  title="Tickets"
                  value={cae.toLocaleString()}
                  // precision={0}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={""}
                  suffix=""
                  className="cae-stat"
                />
              </Badge>
            </div>
            <div className="stat">
              <Statistic
                title="Neto"
                value={neto.toLocaleString()}
                // precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={""}
                suffix=""
              />
            </div>
            <div className="stat">
              <Statistic
                title="IVA"
                value={iva.toLocaleString()}
                // precision={0}
                valueStyle={{ color: "#A149FA" }}
                prefix={""}
                suffix=""
              />
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}

      <div className="table-container-sales">
        {data
          ? data.map((e, i) => {
              console.log(i);
              return (
                <div key={i}>
                  <div className="table-header">
                    <div className="name-badge">
                      <Badge
                        count={((e.total / total) * 100).toFixed(2)}
                        offset={[25, 12]}
                        color="lime"
                        title="Porcentage"
                      >
                        <h3>{e.name}</h3>
                      </Badge>
                    </div>

                    <div className="total-box">
                      <h5 className="total-title">Total</h5>
                      <div className="table-total">
                        {e.total.toLocaleString()}
                      </div>
                    </div>

                    <div className="clientes-box">
                      <h5 className="clientes-title">Clientes</h5>
                      <div className="clientes-total">{e.people}</div>
                    </div>
                  </div>

                  {/* <h2>Algo</h2> */}
                  <SalesTable data={e} />
                </div>
              );
            })
          : !loading && <>Select a date range</>}
        {loading && (
          <div className="spinner">
            {" "}
            <Spin />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
