import React from "react";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Filter from "../../components/Filter";
import EditableTable from "../../components/Table";
const Registros = () => {
  return (
    <div className="container">
      <Navbar />
      <Filter />
      <EditableTable />
    </div>
  );
};

export default Registros;
