import React from "react";
import Navbar from "../../components/Navbar";
import Filter from "../../components/Filter/index";

import UserTable from "../../components/UserTable";
import usuarios from "../../assets/usuarios.json";

const Usuarios = () => {
  return (
    <div className="container">
      <Navbar />
      <Filter />
      <UserTable usuarios={usuarios} />
    </div>
  );
};

export default Usuarios;
