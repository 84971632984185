import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Filter from "../../components/Filter/index";

import EmployeeTable from "../../components/EmployeeTable";
import employees from "../../assets/employees.json";

const Employees = () => {
  return (
    <div className="container">
      <Navbar />
      <Filter />
      <EmployeeTable employees={employees} />
    </div>
  );
};

export default Employees;
