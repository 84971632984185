import React from "react";
import Navbar from "../../components/Navbar";
import Filter from "../../components/Filter";
import Category from "../../components/Category";

function Categories() {
  return (
    <div>
      {" "}
      <div className="container">
        <Navbar />
        <Filter />
        <Category />
      </div>
    </div>
  );
}

export default Categories;
