import "./styles.css";
import axios from "axios";
import React, { useEffect, useState } from "react";

import config from "../../utils/axiosconfig";

const WidgetBar = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get("https://api.bluelytics.com.ar/v2/latest", config)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  console.log("blue", data);

  return (
    <div className="wbar-container">
      {data && (
        <div className="cambio">
          <div className="oficial">
            Oficial $ {data.oficial.value_sell.toFixed(2)}
          </div>
          <div className="blue">
            Blue <strong>$ {data.blue.value_sell.toFixed(2)}</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default WidgetBar;
