import React, { useState } from "react";

import "./styles.css";

import { Badge } from "antd";
import WidgetBar from "../../components/WidgetBar";
import FilterTurnos from "../../components/FilterTurnos";
import Navbar from "../../components/Navbar";

import { Table } from "antd";

const moment = require("moment");
require("moment/locale/es");
moment.locale("ES");

const capitalizeFirst = require("../../utils/capitalizeFirst");

const Turnos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Codigo Turno",
      dataIndex: "CodigoTurno",
      key: "CodigoTurno",
      width: "2%",
    },
    {
      title: "Dia",
      dataIndex: "StartDate",
      key: "StartDate",
      width: "5%",
      render: (_, record) => {
        return capitalizeFirst(
          moment(record.StartDate).locale("es").format("dddd")
        );
      },
    },
    {
      title: "Fecha",
      dataIndex: "StartDate",
      key: "StartDate",
      width: "5%",
      render: (_, record) => {
        return moment(record.StartDate).format("L");
      },
    },
    {
      title: "Inicio",
      dataIndex: "StartDate",
      key: "StartDate",
      width: "3%",
      render: (_, record) => {
        return {
          props: {
            style: {
              background: "",
              color: "#2B7A0B",
              fontWeight: 600,
              align: "right",
            },
          },
          children: (
            <div className="green">{moment(record.StartDate).format("LT")}</div>
          ),
        };
      },
    },
    {
      title: "Fin",
      dataIndex: "address",
      key: "address6",
      width: "3%",
      render: (_, record) => {
        if (record.EndDate) {
          return {
            props: {
              style: {
                background: "",
                color: "#B22727",
                fontWeight: 600,
              },
            },
            children: (
              <div className="red">{moment(record.EndDate).format("LT")}</div>
            ),
          };
        } else {
          return "No record";
        }
      },
    },
    {
      title: "Apertura",
      dataIndex: "usuarioApertura",
      key: "usuarioApertura",
      width: "130px",
      render: (_, record) => {
        return record.usuarioApertura;
      },
    },
    {
      title: "Dinero Inicial",
      dataIndex: "DineroInicialCajon",
      key: "DineroInicialCajon",
      width: "100px",
      render: (_, record) => {
        if (record.DineroInicialCajon) {
          return {
            props: {
              style: {
                background: "",
                color: "#2B7A0B",
                fontWeight: 600,
              },
            },
            children: <div className="green">{record.DineroInicialCajon}</div>,
          };
        } else {
          return "No record";
        }
      },
      align: "right",
    },
    {
      title: "Ventas Efectivo",
      dataIndex: "totalTicketsEfectivo",
      key: "totalTicketsEfectivo",
      width: "90px",
      render: (_, record) => {
        if (record.totalTicketsEfectivo) {
          return {
            props: {
              style: {
                background: "",
                color: "#2B7A0B",
                fontWeight: 600,
              },
            },

            children: (
              <Badge
                count={record.nTicketsEfectivo}
                offset={[1, -8]}
                size={"small"}
                overflowCount={999}
                color="lime"
              >
                {/* <Avatar shape="square" size="large" /> */}
                <div className="green">{record.totalTicketsEfectivo}</div>
              </Badge>
            ),
          };
        } else {
          return "No record";
        }
      },
      align: "right",
    },
    {
      title: "Ventas Tarjeta",
      dataIndex: "totalTicketsTarjeta",
      key: "totalTicketsTarjeta",
      width: "80px",
      render: (_, record) => {
        if (record.totalTicketsTarjeta) {
          return {
            props: {
              style: {
                background: "",
                color: "#4169E1",
                fontWeight: 600,
              },
            },

            children: <div className="black">{record.totalTicketsTarjeta}</div>,
          };
        } else {
          let value = 0;
          return {
            props: {
              style: {
                background: "white",
                color: "red",
                fontWeight: 550,
              },
            },
            children: (
              <div>
                <Badge status="error" />
                {value}
              </div>
            ),
          };
        }
      },
      align: "right",
    },
    {
      title: "Ventas Total",
      dataIndex: "",
      key: "address4",
      width: "90px",
      render: (_, record) => {
        return {
          props: {
            style: {
              background: "",
              color: "#FFA500",
              fontWeight: 600,
            },
          },
          children: (
            <div>
              {record.totalTicketsTarjeta + record.totalTicketsEfectivo}
            </div>
          ),
        };
      },
      align: "right",
    },
    {
      title: "Gastos",
      dataIndex: "",
      key: "address4",
      width: "90px",
      render: (_, record) => {
        let totalGastos = record.total_gastos;
        let withBadge = (
          <Badge
            style={{}}
            count={record.cant_gastos}
            offset={[8, 0]}
            size={"small"}
          >
            <div className="red">{totalGastos}</div>
          </Badge>
        );

        let woBadge = <div className="red">{totalGastos}</div>;

        const gastos = () => {
          if (record.cant_gastos > 1) {
            return withBadge;
          } else {
            return woBadge;
          }
        };

        if (record.cant_gastos) {
          return {
            props: {
              style: {
                // background: "white",
                color: "red",
                fontWeight: 550,
              },
            },
            children: <div className="">{gastos()}</div>,
          };
        } else {
          return "No record";
        }
      },
      align: "right",
    },
    {
      title: "Retiros",
      dataIndex: "",
      key: "address4",
      width: "90px",
      render: (_, record) => {
        let totalRetiros = record.total_retiros;

        let withBadge = (
          <Badge
            style={{}}
            count={record.cant_retiros}
            offset={[8, 0]}
            size={"small"}
          >
            <div className="red">{totalRetiros}</div>
          </Badge>
        );

        let woBadge = <div className="red">{totalRetiros}</div>;

        const retiro = () => {
          if (record.cant_retiros > 1) {
            return withBadge;
          } else {
            return woBadge;
          }
        };

        if (record.cant_retiros) {
          return {
            props: {
              style: {
                // background: "white",
                color: "red",
                fontWeight: 550,
              },
            },
            children: retiro(),
          };
        } else {
          return "No record";
        }
      },
      align: "right",
    },
    {
      title: "Queda en caja para cambio",
      dataIndex: "MontoCierre",
      key: "MontoCierre",
      width: "90px",
      align: "right",
      render: (_, record) => {
        if (record.MontoCierre) {
          return {
            props: {
              style: {
                // background: "white",
                color: "red",
                fontWeight: 550,
              },
            },
            children: <div className="red">{record.MontoCierre}</div>,
          };
        } else {
          return "No record";
        }
      },
    },
    {
      title: "Diferencia",
      dataIndex: "address",
      key: "address6",
      width: "90px",
      align: "right",
      render: (_, record) => {
        let dineroInicial,
          ventasEfectivo,
          totalGastos,
          totalRetiros,
          dineroFinal;

        if (record.DineroInicialCajon) {
          dineroInicial = record.DineroInicialCajon;
        } else {
          dineroInicial = 0;
        }

        if (record.totalTicketsEfectivo) {
          ventasEfectivo = record.totalTicketsEfectivo;
        } else {
          ventasEfectivo = 0;
        }

        if (record.total_retiros) {
          totalRetiros = record.total_retiros;
        } else {
          totalRetiros = 0;
        }

        if (record.MontoCierre) {
          dineroFinal = record.MontoCierre;
        } else {
          dineroFinal = 0;
        }

        if (record.total_gastos) {
          totalGastos = record.total_gastos;
        } else {
          totalGastos = 0;
        }

        let a = dineroInicial + ventasEfectivo;
        let b = totalGastos + totalRetiros + dineroFinal;

        let resultado = a - b;

        let suma =
          dineroInicial +
          ventasEfectivo -
          totalGastos -
          totalRetiros -
          dineroFinal;

        let text = "FALTA";

        suma = suma * -1;
        let color = "red";

        if (suma >= 0) {
          text = "SOBRA";
          color = "green";
        }

        return {
          props: {
            style: {
              background: "#F8CB2E",
              color: color,
              fontWeight: 550,
            },
          },
          children: (
            <div className="diferencia">
              {/* {Math.round(suma)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
              {Math.round(suma)}
            </div>
          ),
        };
      },
    },
    {
      title: "Margen de Error %",
      dataIndex: "address",
      key: "address6",
      width: "90px",
      align: "right",
      render: (_, record) => {
        let dineroInicial, ventasEfectivo, totalRetiros, dineroFinal;

        if (record.DineroInicialCajon) {
          dineroInicial = record.DineroInicialCajon;
        } else {
          dineroInicial = 0;
        }

        if (record.totalTicketsEfectivo) {
          ventasEfectivo = record.totalTicketsEfectivo;
        } else {
          ventasEfectivo = 0;
        }

        if (record.total_retiros) {
          totalRetiros = record.total_retiros;
        } else {
          totalRetiros = 0;
        }

        if (record.MontoCierre) {
          dineroFinal = record.MontoCierre;
        } else {
          dineroFinal = 0;
        }

        let suma =
          dineroInicial +
          ventasEfectivo -
          record.total_gastos -
          totalRetiros -
          dineroFinal;

        suma = suma * -1;
        let error = (suma * 100) / ventasEfectivo;

        return {
          props: {
            style: {
              // background: "#F8CB2E",
              // color: 'red',
              // fontWeight: 550,
            },
          },
          children: <div>{error.toFixed(2)}</div>,
        };
      },
    },
    {
      title: "Fecha Cierre",
      dataIndex: "address",
      key: "address6",
      width: "10%",
      render: (_, record) => {
        if (record.EndDate) {
          return {
            props: {
              style: {
                background: "",
                color: "",
                // fontWeight: 550,
              },
            },
            children: (
              <div>{moment(record.fechaCierre).format("LT [-] L")}</div>
            ),
          };
        } else {
          return "No record";
        }
      },
    },
    {
      title: "Cierre",
      dataIndex: "usuarioCierre",
      key: "usuarioCierre",
      width: "130px",
      render: (_, record) => {
        return record.usuarioCierre;
      },
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: () => <a>Delete</a>,
    },
  ];

  const gastoColumns = [
    {
      title: "Number",
      dataIndex: "Date",
      key: "name",
      render: (_, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "age",
    },
    {
      title: "Descripcion",
      dataIndex: "Name",
      key: "address",
      render: (_, record, i) => {
        return <div>{capitalizeFirst(record.Name)}</div>;
      },
    },
    {
      title: "Monto",
      dataIndex: "Debit",
      key: "address",
      render: (_, record, i) => {
        return <div>${record.Debit.toFixed(0)}</div>;
      },
    },
    {
      title: "Usuario",
      dataIndex: "UserName",
      key: "address",
    },
  ];

  const retiroColumns = [
    {
      title: "Number",
      dataIndex: "Date",
      key: "name",
      render: (_, record, i) => {
        return <div>{i + 1}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "age",
    },

    {
      title: "Monto",
      dataIndex: "Debit",
      key: "address",
      render: (_, record, i) => {
        return <div>${record.Debit.toFixed(0)}</div>;
      },
    },
    {
      title: "Usuario",
      dataIndex: "UserName",
      key: "address",
    },
  ];

  return (
    <div className="container">
      <Navbar />
      <WidgetBar></WidgetBar>
      <FilterTurnos setData={setData} setLoading={setLoading} />

      {console.log(data)}
      {data.length > 0 && (
        <div>
          <h3> Reporte de turno de {data[0].name}</h3>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <div>
                    <h4>Detalle de Gastos</h4>
                    <div className="expandable-table">
                      <Table
                        columns={gastoColumns}
                        dataSource={record.gastos}
                        size="small"
                        pagination={false}
                      />
                      <p className="total">
                        Total de Gastos ${record.total_gastos}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4>Detalle de Retiros</h4>
                    <div className="expandable-table">
                      <Table
                        columns={retiroColumns}
                        dataSource={record.retiros}
                        size="small"
                        pagination={false}
                      />
                      <p className="total">
                        Total de Gastos ${record.total_retiros}
                      </p>
                    </div>
                  </div>
                </div>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            dataSource={data[0].turnos}
            rowKey="CodigoTurno"
          />
        </div>
      )}
    </div>
  );
};

export default Turnos;
